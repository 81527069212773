<template>
  <div class="page1">
    <div class="breadcrumb">
       <div style='text-align:right;'>
          <el-button @click='$router.go(-1)'>返回</el-button>
      </div>
    </div>
    <!--主体内容-->
    <div class="content">
      <h2 class="title">
        <div class="title_icon">
        </div>
        行驶本认证
      </h2>
      <!--搜索条件区域-->
      <div class="Wrapper" style="float: left;width: 100%;">
        <el-form label-position="left" label-width="160px" :model="formInline" style="width: 100%; float:left;" :ref="'form'">
          <el-form-item label="会员手机号" label-width="100px"  style="float: left;width: 33%;" prop='mobile'>
              <span>{{formInline.mobile}}</span>
          </el-form-item>
          <el-form-item label="车牌号：" label-width="100px"  style="float: left;width: 33%;" prop='plateNumber'>
              <span>{{formInline.plateNumber}}</span>
          </el-form-item>
          <el-form-item label="提交日期：" label-width="100px"  style="float: left;" prop="applyTime">
            <span>{{formInline.applyTime}}</span>
          </el-form-item>
          <el-form-item label="认证状态：" label-width="100px"  style="float: left;width: 33%;"  prop="authStatus">
            <span v-if="formInline.authStatus == 0">待审核</span>
            <span v-if="formInline.authStatus == 1">已审核</span>
            <span v-if="formInline.authStatus == 2">已作废</span>
          </el-form-item>
          <el-form-item v-if="this.$route.query.authStatus == 0" label="操作人：" label-width="100px"  style="float: left;width: 33%;" prop="operation">
            <span>{{formInline.operation}}</span>
          </el-form-item>
          <el-form-item label="操作日期：" label-width="100px"  style="float: left;width: 50%;" prop="authTime">
            <span>{{formInline.authTime}}</span>
          </el-form-item>
          <el-form-item v-if="this.$route.query.authStatus == 2" label="拒审理由：" style="width: 80%" label-width="100px"  prop="authDesc" >
              <el-input type="textarea" rows="4" placeholder="请输入拒审理由" v-model.trim="formInline.authDesc"></el-input>
          </el-form-item>
          <el-form-item label="车辆认证信息" style="width: 100%;float: left;"  prop="parkCode">
          </el-form-item>
          <div style="width:100%;">
            <el-form-item label="车辆编码：" label-width="100px"  style="float: left;width: 33%;" prop="label">
              <span>{{formInline.frameNo}}</span>
            </el-form-item>
            <el-form-item label="发动机编码：" label-width="100px"  style="float: left;width: 33%;"  prop="parkCode">
              <span>{{formInline.engineNo}}</span>
            </el-form-item>
            <el-form-item label="车主姓名：" label-width="100px" style="float: left;width: 33%" prop="carOwnerName">
              <span>{{ formInline.carOwnerName }}</span>
            </el-form-item>
            <el-row :gutter="20" style="margin-top: 20px; width: 60%">
              <el-col :span="12">
                <div>
                  <!-- mouseover -->
                  <div class="img">
                    <!-- <img :src='formInline.frontAuthImage' @click="showBigPic(formInline.frontAuthImage)" alt="" style="width: 100%; height:200px"> -->
                    <div class="demo-image__preview">
                      <el-image 
                        style="width: 100%; height: 200px"
                        :src="formInline.frontAuthImage" 
                        :preview-src-list="[formInline.frontAuthImage]">
                      </el-image>
                    </div>
                  </div>
                </div>
              </el-col>
              <el-col :span="12">
                <div class="img">
                  <!-- <img :src='formInline.backAuthImage' @click="showBigPic(formInline.backAuthImage)" alt="" style="width: 100%; height:200px"> -->
                  <div class="demo-image__preview">
                    <el-image 
                      style="width: 100%; height: 200px"
                      :src="formInline.backAuthImage" 
                      :preview-src-list="[formInline.backAuthImage]">
                    </el-image>
                  </div>
                </div>
              </el-col>
            </el-row>
          </div>
          <div style="margin: 0 auto;width: 700px;margin-top: 50px;" v-if="this.$route.query.authStatus == 0">
             <el-button type="primary" class="buttonclass" @click="through">通过</el-button>
             <el-button type="" class="buttonclass" @click="Refused">拒绝</el-button>
             <el-button type="" class="buttonclass" @click="$router.go(-1)">取消</el-button>
          </div>
        </el-form>
      </div>
    </div>
  <!-- 弹窗 -->
    <picDetail :detailFlag="dialogVisible"  @close="dialogVisible=false"></picDetail>
   <transition name="fade">
      <div class="mask" v-show="bigPic" :style="{'width': '500px','height': 'auto','margin': '0 auto','z-index': '10000', 'background-image': picUrl}" @click="bigPic=false">
      </div>
    </transition>
  </div>
</template>
<script>
  import {
    request
   // dateFormat
  } from '../../common/js/public.js'
  import picDetail from './picDetail'
  export default {
    name: 'hello',
    data () {
      return {
        dialogVisible: false,
        picUrl: '',
        bigPic: false,
        imageUrl: '',
        formInline: {
          mobile: '',
          plateNumber: '',
          remark: '',
          applyTime: '',
          authStatus: '',
          operation: '',
          authTime: '',
          frameNo: '',
          engineNo: '',
          authImage: '',
          authDesc: ''
        }
      }
    },
    methods: {
      // 拒绝按钮 打开弹框
      Refused() {
        this.dialogVisible = true;
      },
      // 点击放大图片
      showBigPic (url) {
        if (!url) {
          return false;
        }
        this.picUrl = `url("${url}")`;
        this.bigPic = true;  	
      },
      // 通过
      through () {
        this.$confirm("请您确认审核资料是否无误！", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
          cancelButtonText: "取消",
          type: "warning"
        }).then(() => {
          let opt = {
            method: 'get',
            url: '/acb/2.0/memberCarAuth/auth',
            data: {
              memberCarauthId: this.$route.query.memberCarauthId,
              authDesc: this.formInline.remark,
              authStatus: '1'
            },
            success: (res) => {
              this.$router.go(-1)
            }
          }
          request(opt)
        }).catch(err => {})
      },
      // 获取数据
      getDataList () {
        let opt = {
          method: 'get',
          url: '/acb/2.0/memberCarAuth/getById',
          data: {
            memberCarauthId: this.$route.query.memberCarauthId
          },
          success: (res) => {
            if (res.state == 0) {
              this.formInline = res.value;
            }
          }, 
          fail: (res) => {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine')
            });
          }
        }
        request(opt)
      }
    },
    components: {
      picDetail
    },
    created () {
      this.getDataList()
    },
    mounted () {
    },
    computed: {}
  }
</script><!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
  .Wrapper
    overflow hidden
  .breadcrumb
    height: 50px;
  .content
    background: #FFFFFF;
    overflow: hidden;
    border-radius: 4px;
    border: 1px solid #d9d9d9;
    padding: 20px;
  .uploadPic
    float: left;
    margin-left: 150px;
    width: 295px;
    height: 178px;
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    img{
      width: 100%;
      height: 100%;
    }
.mask
	background: rgba(0,0,0,0) no-repeat center/contain;
	// background: rgba(49,53,65,0.60) no-repeat center/contain;
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 999;
</style>
<style>
  .dialog_button{
    width: 100%;
    height: 40px;
  }
  .buttonclass{
    width: 140px;
    margin-left: 72px;
    margin-right: 55px;
  }
  .img{
    background: url("car-pic.png") center no-repeat;
    width: 100%;
    height: 200px;
  }
</style>
